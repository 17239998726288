import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <Seo />  
  </Layout>
);

export default IndexPage;
